import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../../layouts/default/layout'
import FlexContent, {
    FlexTextHeading,
} from '../../components/flexcontent/flexcontent'
import ShareComponent from '../../components/share-component/ShareComponent'
import SEO from '../../components/seo'
import MoreIdeas from '../../components/more-ideas/MoreIdeas'
import './ideas-tutorials-item.scss'

const TutorialItem = ({ data: { tutorial, relatedTutorials } }) => {
    const {
        title,
        content,
        featured_media,
        acf,
        dateFormatted,
        author,
    } = tutorial
    return (
        <Layout theme={'light'} headerSpacing={true}>
            <SEO
                yoast={tutorial.yoast}
                article={true}
                title={title}
                type="Person"
                author={author.name}
                description={tutorial.content}
                dateModified={tutorial.modified}
                datePublished={tutorial.date}
            />
            <main className="TutorialItem">
                <header className="container-fluid mb-yd">
                    <div className="row align-items-center">
                        {featured_media && featured_media.localFile && (
                            <div className="col-12 col-md-6">
                                <Img
                                    critical
                                    fluid={
                                        featured_media.localFile.childImageSharp
                                            .fluid
                                    }
                                    alt={featured_media.alt_text}
                                    title={featured_media.title}
                                />
                            </div>
                        )}
                        <div className="col-12 col-md-6">
                            <h1
                                className="mb-5 mt-3"
                                dangerouslySetInnerHTML={{
                                    __html: title,
                                }}
                            />
                            <div className="type-link mb-1">{`Tutorial by ${
                                author.name
                            }`}</div>
                            <div className="type-link">{dateFormatted}</div>
                        </div>
                    </div>
                </header>
                {content && (
                    <section className="flex-item px-yd">
                        <FlexTextHeading flexItem={{ content }} />
                    </section>
                )}
                {acf && <FlexContent contents={acf.contents_tutorials} />}
                <div className="px-yd mt-yd">
                    <ShareComponent slug={`/ideas/tutorials/${tutorial.slug}`} />
                    <MoreIdeas
                        posts={relatedTutorials ? relatedTutorials.edges : []}
                        post_type="tutorial"
                    />
                </div>
            </main>
        </Layout>
    )
}

export default TutorialItem

export const pageQuery = graphql`
    query($id: String!, $tutorialTypeIds: [Int!]) {
        tutorial: wordpressWpTutorials(id: { eq: $id }) {
            ...YoastTutorials
            id
            slug
            title
            content
            dateFormatted: date(formatString: "MM.DD.YYYY")
            author {
                name
            }
            acf {
                contents_tutorials {
                    __typename
                    ... on WordPressAcf_image {
                        ...FlexContentImageFragment
                    }
                    ... on WordPressAcf_two_image {
                        ...FlexContentTwoImageFragment
                    }
                    ... on WordPressAcf_quote {
                        ...FlexContentQuoteFragment
                    }
                    ... on WordPressAcf_text_heading {
                        ...FlexContentTextHeadingFragment
                    }
                    ... on WordPressAcf_text_and_image {
                        ...FlexContentTextImageFragment
                    }
                    ... on WordPressAcf_two_text {
                        ...FlexContentTwoTextFragment
                    }
                    ... on WordPressAcf_code {
                        ...FlexContentCodeFragment
                    }
                    ... on WordPressAcf_single_video {
                        ...FlexContentVideoFragment
                    }
                    ... on WordPressAcf_two_video {
                        ...FlexContentTwoVideoFragment
                    }
                }
            }
            featured_media {
                alt_text
                title
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 800, maxHeight: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        } #End of Tutorial Query
        relatedTutorials: allWordpressWpTutorials(
            limit: 3
            filter: {
                tutorialtypes: {
                    elemMatch: { wordpress_id: { in: $tutorialTypeIds } }
                }
                id: { ne: $id }
            }
        ) {
            edges {
                node {
                    ...IdeasTutorialListItemFragment
                }
            }
        }
    }
`
